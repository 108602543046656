import React from "react";
import {
  Container,
  Box,
  useTheme,
  Typography,
  Grid,
  Card,
  CardActionArea,
  Link,
  Avatar,
} from "@mui/material";
import { Layout } from "../containers/Layout";
import { Seo } from "../components/Seo";
import { graphql, navigate, PageProps } from "gatsby";
import { Article, Home } from "@mui/icons-material";

const NotFoundPage = ({
  data: {
    allSanityService: { edges },
  },
}: PageProps<{
  allSanityService: Pick<GatsbyTypes.Query["allSanityService"], "edges">;
}>) => {
  const theme = useTheme();
  const backgroundColor = theme.palette.primary.main;
  const color = theme.palette.primary.contrastText;
  const pageTitle = "お探しのページが見つかりませんでした";
  const description = pageTitle;

  return (
    <Layout>
      <Seo title={pageTitle} description={description} />
      <Box py={4} sx={{ backgroundColor }}>
        <Container maxWidth="md">
          <Typography component="h1" variant="h5" align="center" color={color}>
            {pageTitle}
          </Typography>
        </Container>
      </Box>
      <Box py={4}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            {[
              { title: "トップに戻る", url: "/", icon: <Home /> },
              { title: "ブログを見る", url: "/blog", icon: <Article /> },
            ].map(({ title, url, icon }, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card variant="outlined">
                  <CardActionArea
                    onClick={() => {
                      navigate(url);
                    }}
                  >
                    <Box display="flex" p={1}>
                      <Box>
                        <Avatar variant="rounded" sx={{ backgroundColor }}>
                          {icon}
                        </Avatar>
                      </Box>
                      <Box ml={1}>
                        <Link
                          href={url}
                          color="inherit"
                          underline="none"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {title}
                        </Link>
                      </Box>
                    </Box>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
            {edges.map(({ node }, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card variant="outlined">
                  <CardActionArea
                    onClick={() => {
                      navigate(`/${node.slug}`);
                    }}
                  >
                    <Box display="flex" p={1}>
                      <Box>
                        <Avatar variant="rounded" sx={{ backgroundColor }}>
                          <Box
                            sx={{ ":before": { content: `"${node.name[0]}"` } }}
                          />
                        </Avatar>
                      </Box>
                      <Box ml={1}>
                        <Link
                          href={`/${node.slug}`}
                          color="inherit"
                          underline="none"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {node.name}
                        </Link>
                      </Box>
                    </Box>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  {
    allSanityService {
      edges {
        node {
          slug
          name
          _id
        }
      }
    }
  }
`;
